// @mui icons
/* import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube"; */

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/Reminisce.me - Blue 1 - No Tagline.svg";
const date = new Date().getFullYear();

export default {
  brand: {
    name: "Reminisce.Me",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      /*  icon: <FacebookIcon />,
      link: "https://www.facebook.com/CreativeTim/", */
    },
    {
      /*   icon: <TwitterIcon />,
      link: "https://twitter.com/creativetim", */
    },
    {
      /*  icon: <GitHubIcon />,
      link: "https://github.com/creativetimofficial", */
    },
    {
      /*     icon: <YouTubeIcon />,
      link: "https://www.youtube.com/channel/UCVyTG4sCw-rOvB9oHkzZD1w", */
    },
  ],
  menus: [
    {
      name: "",
      items: [
        { name: "", href: "https://www.creative-tim.com/presentation" },
        { name: "", href: "https://www.creative-tim.com/templates/free" },
        { name: "", href: "https://www.creative-tim.com/templates/premium" },
        { name: "", href: "https://www.creative-tim.com/blog" },
      ],
    },
    {
      name: "",
      items: [
        { name: "", href: "https://iradesign.io/" },
        { name: "", href: "https://www.creative-tim.com/bits" },
        { name: "", href: "https://www.creative-tim.com/affiliates/new" },
      ],
    },
    {
      name: "",
      items: [
        { name: "", href: "https://www.creative-tim.com/contact-us" },
        { name: " ", href: "https://www.creative-tim.com/knowledge-center" },
        { name: "", href: "https://services.creative-tim.com/" },
        { name: "", href: "https://www.creative-tim.com/sponsorships" },
      ],
    },
    {
      name: "",
      items: [
        { name: "", href: "https://www.creative-tim.com/terms" },
        { name: "", href: "https://www.creative-tim.com/privacy" },
        { name: "", href: "https://www.creative-tim.com/license" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date}{" "}
      <MKTypography
        component="a"
        href="https://www.creative-tim.com"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        Reminisce.Me
      </MKTypography>
      .
    </MKTypography>
  ),
};
