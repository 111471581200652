import React from "react";
import image1 from "../../../../assets/images/1.png";
import image2 from "../../../../assets/images/2.png";
import image3 from "../../../../assets/images/3.png";
import image4 from "../../../../assets/images/4.png";
import image5 from "../../../../assets/images/5.png";
import image6 from "../../../../assets/images/6.png";

// Assuming images is an array of URLs or import statements for your images
const images = [image1, image2, image3, image4, image5, image6];

const PdfImagesDisplay = () => {
  return (
    <div style={{ width: "50%", height: "100vh", overflow: "auto", alignItems: "center" }}>
      {images.map((image, index) => (
        <img
          src={image}
          alt={`Page ${index + 1}`}
          key={index}
          style={{ width: "100%", height: "auto" }}
        />
      ))}
    </div>
  );
};

export default PdfImagesDisplay;
