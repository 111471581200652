import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import macbook from "assets/images/BeFunky-collage.jpg";

function Newsletter() {
  return (
    <MKBox component="section" pt={6} my={6}>
      <Container>
        <Grid container alignItems="center" spacing={0}>
          <Grid item xs={12} md={6}>
            <MKTypography variant="h4">Be the first to see the news</MKTypography>
            <MKTypography variant="body2" color="text" mb={3}></MKTypography>

            {/* Embed Google Form */}
            <iframe
              src="https://docs.google.com/forms/d/e/1FAIpQLSfCjJZ533CIF4wziu0IUJYOOqbTF4DIDq18ajIzN2jxNd85jQ/viewform?embedded=true"
              width="100%"
              height="467"
              frameBorder="0"
              marginHeight="0"
              marginWidth="0"
            >
              Loading…
            </iframe>
          </Grid>
          <Grid item xs={12} md={6}>
            <MKBox width="100%" height="100%">
              <MKBox
                component="img"
                src={macbook}
                alt="macbook"
                width="100%"
                height="100%"
                style={{ objectFit: "contain" }}
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Newsletter;
