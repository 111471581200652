import React, { useState, useEffect } from "react";
import { Container, Typography, Box } from "@mui/material";
import EventIcon from "@mui/icons-material/Event";

const ComingSoon = () => {
  const calculateTimeLeft = () => {
    const difference = +new Date("2023-12-31") - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearTimeout(timer);
  });

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: 8,
        }}
      >
        <EventIcon color="primary" style={{ fontSize: 60 }} />
        <Typography component="h1" variant="h5">
          Coming Soon
        </Typography>
        <Typography component="h2" variant="h6" gutterBottom>
          Were launching in...
        </Typography>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          {timeLeft.days} Days {timeLeft.hours} Hours {timeLeft.minutes} Minutes {timeLeft.seconds}{" "}
          Seconds
        </Typography>
      </Box>
    </Container>
  );
};

function App() {
  return (
    <div className="App">
      <ComingSoon />
    </div>
  );
}

export default App;
