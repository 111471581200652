import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import image2 from "assets/images/graphic.svg";
import image1 from "assets/images/test.svg";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@emotion/react";

const MyComponent = () => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const imageSize = isMobile
    ? { width: "80vw", height: "auto" } // Using 80% of the viewport width for mobile view
    : { width: "90vw", height: "90vh" };

  return (
    <Box
      sx={{
        backgroundColor: "white",
        borderRadius: 1,
      }}
    >
      <Typography
        variant="h1"
        sx={{
          fontFamily: "Times New Roman, sans-serif",
          lineHeight: { xs: 1.5, md: 2 },
          textAlign: "center",
          padding: 1,
          color: "black",
          fontSize: { xs: "2.8rem", sm: "1rem", md: "3.5rem" },
        }}
      ></Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          {" "}
          {/* First Image */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "auto%",
            }}
          >
            <img
              src={image1}
              style={{
                objectFit: "contain",
                ...imageSize,
              }}
              alt="SVG 1"
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          {" "}
          {/* First Text */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontFamily: "Times New Roman, sans-serif",
                lineHeight: { xs: 1.5, md: 2 },
                textAlign: "center",
                padding: 1,
                color: "black",
                fontSize: { xs: "0.8rem", sm: "1rem", md: "1.5rem" },
              }}
            >
              Reminisce Me believes in honoring the fullness of each life, transcending traditional
              obituaries that often capture just a glimpse of a person. <br /> <br /> We&apos;re
              committed to immortalizing the vibrant, intricate, and authentic narratives of your
              loved ones, celebrating their unique stories and impactful moments. <br />
              <br />
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          {" "}
          {/* Second Image */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: isMobile ? "auto" : "100%", // if it's mobile view, adjust the height to be auto
            }}
          >
            <img
              src={image2}
              style={{
                objectFit: "contain",
                ...imageSize,
              }}
              alt="SVG 2"
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          {" "}
          {/* Second Text */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontFamily: "Times New Roman, sans-serif",
                lineHeight: { xs: 1.5, md: 2 },
                textAlign: "center",
                padding: 1,
                color: "black",
                fontSize: { xs: "0.8rem", sm: "1rem", md: "1.5rem" },
              }}
            >
              With Reminisce Me, sharing your loved one&apos;s story is easy and stress-free. Forget
              about filling out lengthy forms or spending hours typing. <br /> <br /> Our platform
              allows users to effortlessly share memories by simply responding to video prompts.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MyComponent;



